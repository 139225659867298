
<div class="ingredient-row">
    <div class="ingredient-name">
        <label>Ingredient Name</label>
        <input :name="'Ingredient[' + index + '][name]'" v-model="l_name" type="text" />
    </div>
    <div class="ingredient-q-purchased">
        <label>Quantity Purchased</label>
        <div class="ingredient-combined">
            <input :name="'Ingredient[' + index + '][quantity]'" v-model="p_quantity_purchased" type="text" />
            <select :name="'Ingredient[' + index + '][measurement]'" v-model="l_measurement_purchased">
                <option value="kilograms">kilograms</option>
                <option value="grams">grams</option>
                <option value="mls">mls</option>
                <option value="tbsp">tbsp</option>
                <option value="tsp">tsp</option>
                <option value="litres">litres</option>
                <option value="cups">cups</option>
                <option value="each">each</option>
            </select>
        </div>
    </div>
    <div class="ingredient-c-goods">
        <label>Cost of Goods</label>
        <div class="currency">
            <input :name="'Ingredient[' + index + '][cost]'" v-model="l_cost" type="text" />
        </div>
    </div>
    <div class="ingredient-q-used">
        <label>Quantity Used</label>
        <div class="ingredient-combined">
            <input :name="'Ingredient[' + index + '][used]'" v-model="p_quantity_used" type="text" />
            <select :name="'Ingredient[' + index + '][measurement_used]'" v-model="l_measurement_used">
                <option v-for="(item,index) in measurement_options"
                        v-bind:key="item.value" :value="item.value">{{item.value}}
                </option>
            </select>
        </div>
    </div>
    <div class="ingredient-t-total">
        <div class="currency">
            <input v-model="l_total" disabled type="text" />
            <input :name="'Ingredient[' + index + '][total]'" v-model="l_total" type="hidden" />
        </div>
    </div>
</div>
<!--
<div class="row ingredient-row" :class="{ 'first': index == 0 }">
    <div class="col-12 sm:col-6">
        <div class="row">
            <div class="col-12 sm:col-6 lg:col-7">
                <div class="field-group">
                    <div class="field">
                        <label>Ingredient Name</label>
                        <input :name="'Ingredient[' + index + '][name]'" v-model="l_name" type="text" />
                    </div>
                </div>
            </div>
            <div class="col-12 sm:col-6 lg:col-5">
                <div class="row field-group">
                    <div class="col-6 xs:col-6">
                        <label>Quantity Purchased</label>
                        <div class="field">
                            <input :name="'Ingredient[' + index + '][quantity]'" v-model="p_quantity_purchased" type="text" />
                        </div>
                    </div>
                    <div class="col-6 xs:col-6">
                        <div class="field">
                            <select :name="'Ingredient[' + index + '][measurement]'" v-model="l_measurement_purchased">
                                <option value="kilograms">kilograms</option>
                                <option value="grams">grams</option>
                                <option value="mls">mls</option>
                                <option value="tbsp">tbsp</option>
                                <option value="tsp">tsp</option>
                                <option value="litres">litres</option>
                                <option value="cups">cups</option>
                                <option value="each">each</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 sm:col-6">
        <div class="row">
            <div class="col-6 xs:col-3">
                <div class="field-group">
                    <label>Cost of Goods</label>
                    <div class="field">
                        <div class="currency">
                            <input :name="'Ingredient[' + index + '][cost]'" v-model="l_cost" type="text" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 xs:col-3">
                <div class="field-group">
                    <label>Quantity Used</label>
                    <div class="field">
                        <input :name="'Ingredient[' + index + '][used]'" v-model="p_quantity_used" type="text" />
                    </div>
                </div>
            </div>
            <div class="col-6 xs:col-3">
                <div class="field">
                    <select :name="'Ingredient[' + index + '][measurement_used]'" v-model="l_measurement_used">
                        <option v-for="(item,index) in measurement_options"
                                v-bind:key="item.value" :value="item.value">{{item.value}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-6 xs:col-3">
                <div class="field">
                    <div class="currency">
                        <input v-model="l_total" disabled type="text" />
                        <input :name="'Ingredient[' + index + '][total]'" v-model="l_total" type="hidden" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
-->
