const style = document.head.appendChild(document.createElement('style'));
style.textContent = /*css*/`
  ready-vimeo-player {
    position: relative;
    aspect-ratio: 16 / 9;
    position: absolute;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media (min-width: 768px) {
    ready-vimeo-player {
      left: 280px;
    }
  }

  ready-vimeo-player video {
    height: 100%;
    aspect-ratio: 16 / 9;
    position: absolute;
  }

`;

class ReadyVimeoPlayer extends (globalThis.HTMLElement ?? class {
}) {

    static _warmConnections() {
        if (ReadyVimeoPlayer.preconnected) return;
        ReadyVimeoPlayer.preconnected = true;
    }

    connectedCallback() {
        this.src = this.getAttribute('src');

        if (!this.src) {
            return
        }

        this.setupElement();

        window.addEventListener('load', () => {
            this.videoEl.src = this.src;
        })
    }

    setupElement() {
        if (this.classList.contains('rvp-active')) return;
        this.classList.add('rvp-active');

        this.videoEl = document.createElement('video');

        //@todo make these options
        this.videoEl.playsInline = true;
        this.videoEl.muted = true;
        this.videoEl.autoplay = true;
        this.videoEl.loop = true;

        this.append(this.videoEl);
    }
}

if (globalThis.customElements && !globalThis.customElements.get('ready-vimeo-player')) {
    globalThis.customElements.define('ready-vimeo-player', ReadyVimeoPlayer);
}

