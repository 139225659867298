(() => {
    /*
    *   Suggested structure:
    *   <div class="social-share">
    *       <a href="link" class="social-link">link</a>
    *   </div>
    */
    const socialShare = document.querySelector('.social-share')

    if (socialShare) {
        const shareLinks = socialShare.querySelectorAll('.social-link')
        shareLinks.forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault()

                const url = link.href

                const width = 575
                const height = 400

                const left = (document.documentElement.clientWidth / 2 - width / 2)
                const top = (document.documentElement.clientHeight - height) / 2

                const opts = `${'status=1,resizable=yes'
                    + ',width='}${width},height=${height
                },top=${top},left=${left}`

                const win = window.open(url, '', opts)
                win.focus()
            })
        })
    }
})()
