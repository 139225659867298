
<div class="container">
    <div class="row">
        <div class="col-12 sm:col-6">
            <h2>Recipe</h2>
            <div class="row">
                <div class="col-6">
                    <div class="field">
                        <label>Venue Name</label>
                        <input id="VenueName" name="VenueName" type="text" required />
                    </div>
                </div>
                <div class="col-6">
                    <div class="field">
                        <label>Recipe Name</label>
                        <input id="RecipeName" name="RecipeName" type="text" required />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div class="field-group">
                        <div class="field">
                            <label>Number of servings</label>
                            <input id="Servings" name="Servings" type="number" required v-model="servings" />
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="field">
                        <label class="label">Price per serving</label>
                        <div class="currency">
                            <input id="ServePrice" name="ServePrice" type="text" required v-model="serving_cost" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 sm:col-6">
            <h2>Method</h2>
            <div class="row">
                <div class="col-12">
                    <div class="field">
                        <label>Add your method</label>
                        <textarea id="Method" name="Method" rows="5"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h2>Ingredients</h2>
        </div>
    </div>
    <div class="ingredients">
        <Ingredient
            v-for="(ingredient, index) in ingredients"
            :key="index"
            :index="index"
            :id="ingredient.id"
            :name="ingredient.name"
            :measurement_purchased="ingredient.measurement_purchased"
            :measurement_used="ingredient.measurement_used"
            :quantity_purchased="ingredient.quantity_purchased"
            :quantity_used="ingredient.quantity_used"
            :cost="ingredient.cost"
            :total="ingredient.total"
            @update="updateIngredient"
        ></Ingredient>
    </div>
    <div class="ingredients-actions">
        <a class="button-extra" @click="addIngredient">Add Ingredient</a>
    </div>
    <div class="ingredients-totals-wrapper">
        <div class="container">
            <div class="ingredients-totals__container">
                <div class="ingredients-totals__title">
                    Summary
                </div>
                <div class="ingredients-totals__content">
                    <div class="ingredient-total-group">
                        <div class="ingredient-total">
                            <label>Total Batch Cost</label>
                            <div class="currency">
                                <input v-model="total_batch_cost" type="text" disabled />
                                <input id="TotalCost" name="TotalCost" v-model="total_batch_cost" type="hidden" />
                            </div>
                        </div>
                        <div class="ingredient-total">
                            <label>(Inc GST)</label>
                            <div class="currency">
                                <input v-model="with_gst" type="text" disabled />
                                <input id="TotalCostGst" name="TotalCostGst" v-model="with_gst" type="hidden" />
                            </div>
                        </div>
                    </div>
                    <div class="ingredient-total-group">
                        <div class="ingredient-total">
                            <label>Cost Per Serve</label>
                            <div class="currency">
                                <input v-model="total_per_serve" type="text" disabled />
                                <input id="TotalPerServe" name="TotalPerServe" v-model="total_per_serve" type="hidden" />
                            </div>
                        </div>
                        <div class="ingredient-total">
                            <label>Total Sales</label>
                            <div class="currency">
                                <input v-model="total_sales_amout" type="text" disabled />
                                <input id="TotalSales" name="TotalSales" v-model="total_sales_amout" type="hidden" />
                            </div>
                        </div>
                    </div>
                    <div class="ingredient-total-group">
                        <div class="ingredient-total">
                            <label>Gross Profit %</label>
                            <div>
                                <input v-model="p_food_gross_profit" type="text" disabled />
                                <input id="GrossProfit" name="GrossProfit" v-model="p_food_gross_profit" type="hidden" />
                            </div>
                        </div>
                        <div class="ingredient-total">
                            <label>Markup %</label>
                            <div>
                                <input v-model="p_food_markup" type="text" disabled />
                                <input id="FoodMarkup" name="FoodMarkup" v-model="p_food_markup" type="hidden" />
                            </div>
                        </div>
                    </div>
                    <div class="ingredient-total">
                        <label>Total Generated Profit</label>
                        <div class="currency">
                            <input v-model="total_profit" type="text" disabled />
                            <input id="TotalProfit" name="TotalProfit" v-model="total_profit" type="hidden" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ingredient-download" v-if="complete">
        <button type="submit" class="btn btn-primary">Download PDF</button>
    </div>
</div>
