const style = document.head.appendChild(document.createElement('style'));
style.textContent = /*css*/`

  vimeo-player {
    aspect-ratio: 16 / 9;
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
  }

  vimeo-player > .vp-playbtn {
    font-size: 10px;
    padding: 0;
    width: 6.5em;
    height: 4em;
    background: rgba(23, 35, 34, .75);
    z-index: 1;
    opacity: .8;
    border-radius: .5em;
    transition: opacity .2s ease-out, background .2s ease-out;
    outline: 0;
    border: 0;
    cursor: pointer;
  }

  vimeo-player:hover > .vp-playbtn {
    background-color: rgb(0, 173, 239);
    opacity: 1;
  }

  /* play button triangle */
  vimeo-player > .vp-playbtn::before {
    content: '';
    border-style: solid;
    border-width: 10px 0 10px 20px;
    border-color: transparent transparent transparent #fff;
  }

  vimeo-player > .vp-playbtn,
  vimeo-player > .vp-playbtn::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  /* Post-click styles */
  vimeo-player.vp-active {
    cursor: unset;
  }

  vimeo-player.vp-active::before,
  vimeo-player.vp-active > .vp-playbtn {
    opacity: 0;
    pointer-events: none;
  }
`;
class VimeoPlayer extends (globalThis.HTMLElement ?? class {}) {

    static _warmConnections() {
        if (VimeoPlayer.preconnected) return;
        VimeoPlayer.preconnected = true;

        addPrefetch('preconnect', 'https://player.vimeo.com');
        addPrefetch('preconnect', 'https://i.vimeocdn.com');
        addPrefetch('preconnect', 'https://f.vimeocdn.com');
        addPrefetch('preconnect', 'https://fresnel.vimeocdn.com');
    }

    connectedCallback() {
        this.src = this.getAttribute('src');
        this.title = this.getAttribute('title') || 'Play video';
        this.inline = this.getAttribute('inline') || false;

        if(this.inline) {

            let { width, height } = getThumbnailDimensions(this.getBoundingClientRect());
            let devicePixelRatio = window.devicePixelRatio || 1;
            if (devicePixelRatio >= 2) devicePixelRatio *= .75;
            width = Math.round(width * devicePixelRatio);
            height = Math.round(height * devicePixelRatio);

            fetch(`https://vimeo.com/api/v2/video/${this.src}.json`)
                .then(response => response.json())
                .then(data => {
                    let thumbnailUrl = data[0].thumbnail_large;
                    thumbnailUrl = thumbnailUrl.replace(/-d_[\dx]+$/i, `-d_${width}x${height}`);
                    this.style.backgroundImage = `url("${thumbnailUrl}")`;
                });

            let playBtnEl = this.querySelector('.vp-playbtn');

            if (!playBtnEl) {
                playBtnEl = document.createElement('button');
                playBtnEl.type = 'button';
                playBtnEl.setAttribute('aria-label', this.title);
                playBtnEl.classList.add('vp-playbtn');
                this.append(playBtnEl);
            }
            playBtnEl.removeAttribute('href');


            // On hover (or tap), warm up the TCP connections we're (likely) about to use.
            this.addEventListener('pointerover', VimeoPlayer._warmConnections, {
                once: true
            });

            this.addEventListener('click', this.addIframe);

        } else {
            const observer = new IntersectionObserver((entries) => {
                for (const entry of entries) {
                    if(entry.isIntersecting) {
                        this.addIframe()
                    }
                }
            });

            observer.observe(this);
        }
    }

    addIframe() {
        if (this.classList.contains('vp-active')) return;
        this.classList.add('vp-active');

        const iframeEl = document.createElement('iframe');
        iframeEl.title = this.title;
        iframeEl.allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';

        if(this.inline) {
            iframeEl.src = `https://player.vimeo.com/video/${encodeURIComponent(this.src)}?autoplay=1`;
            this.style.backgroundImage = null;
        } else {
            iframeEl.src = `https://player.vimeo.com/video/${encodeURIComponent(this.src)}?autoplay=1&controls=0&loop=1&muted=1&playsinline=0&preload=metadata&transparent=0&autopause=0`;
        }

        iframeEl.frameBorder = 0;
        iframeEl.allowFullscreen = true
        this.append(iframeEl);

        // Set focus for a11y
        iframeEl.addEventListener('load', iframeEl.focus, { once: true });
    }
}

if (globalThis.customElements && !globalThis.customElements.get('vimeo-player')) {
    globalThis.customElements.define('vimeo-player', VimeoPlayer);
}

/**
 * Add a <link rel={preload | preconnect} ...> to the head
 */
function addPrefetch(kind, url, as) {
    const linkElem = document.createElement('link');
    linkElem.rel = kind;
    linkElem.href = url;
    if (as) {
        linkElem.as = as;
    }
    linkElem.crossorigin = true;
    document.head.append(linkElem);
}

function getThumbnailDimensions({ width, height }) {
    let roundedWidth = width;
    let roundedHeight = height;

    // If the original width is a multiple of 320 then we should
    // not round up. This is to keep the native image dimensions
    // so that they match up with the actual frames from the video.
    //
    // For example 640x360, 960x540, 1280x720, 1920x1080
    //
    // Round up to nearest 100 px to improve cacheability at the
    // CDN. For example, any width between 601 pixels and 699
    // pixels will render the thumbnail at 700 pixels width.
    if (roundedWidth % 320 !== 0) {
        roundedWidth = Math.ceil(width / 100) * 100;
        roundedHeight = Math.round((roundedWidth / width) * height);
    }

    return {
        width: roundedWidth,
        height: roundedHeight
    };
}
