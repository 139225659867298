
import Vue from 'vue'
import Calculator from '../vue/Calculator.vue'

(() => {
    if (!document.querySelector('#calculator')) return

    Vue.component('calculator', Calculator)

    new Vue({
        el: '#calculator',
    })
})()
