import { tns } from "tiny-slider"

function setup(section) {

    const controlsContainer = section.querySelector('.gallery__controls')
    const container = section.querySelector('.gallery__image-wrapper')
    const navContainer = section.querySelector('.gallery__navigation')

    tns({
        container,
        controlsContainer,
        navContainer
    })
}

function init() {
    const galleries = Array.from(document.querySelectorAll('.gallery'))

    if (!galleries.length) {
        return
    }

    galleries.forEach(setup)
}

init()
